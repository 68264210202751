<template>
  <div class="row">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage">
    </loading>
    <div class="col-12 col-md-12">
      <UserBar :period="period" :OPAnalyst="OPAnalyst" v-on:changePeriod="updatePeriod($event)"></UserBar>
    </div>
    <!-- Motivo cancelamento -->
    <div class="col-12 col-md-12 col-sm-12">
      <div class="row">
        <div class="card-body p-0">
          <div class="card py-5">
            <div class="row col-12 border-bottom">
              <h3 class="mb-0 col-8 cold-sm-8 col-md-8 pb-2">Motivo de cancelamento</h3>
              <input type="text" class="mb-0 col-4 cold-sm-4 col-md-4 pb-2 form-control" v-model="search" @input="customFilter" :placeholder="'Pesquisar'"/>
              <div class="col-1 pb-3">
                <h5 class="box-title mb-0 cursor-pointer" @click="SortByDay()">Dia</h5>
              </div>
              <div class="col-2">
                <h5 class="box-title mb-0">Processo</h5>
              </div>
              <div class="col-2">
                <h5 class="box-title mb-0">Analista</h5>
              </div>
              <div class="col-7">
                <h5 class="box-title mb-0">Motivo</h5>
              </div>
            </div>
            <div v-for="(item, idx) in CancelsDataFiltered" v-bind:key="idx">
              <div class="row col-12 border-bottom py-1">
                <div class="col-1">
                  <li class="list-unstyled">{{ format_date(item.dia) }}</li>
                </div>
                <div class="col-2">
                  <li class="list-unstyled">{{ item.processo }}</li>
                </div>
                <div class="col-2">
                  <li class="list-unstyled">{{ item.nome }}</li>
                </div>
                <div class="col-7">
                  <li class="list-unstyled">{{ item.detalhes_cancelamento }}</li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import KpiService from '@/services/KPI/KpiService'

// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import UserBar from '@/components/Kpi/UserBar.vue'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'OperationalCancels',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('kpi.title') + ' ' + this.$i18n.t('kpi.analyst.operatinalList') + ' - %s'
    }
  },
  data () {
    return {
      period: '',
      OPAnalyst: false,
      isLoading: false,
      fullPage: true,
      CancelsData: [],
      CancelsDataFiltered: [],
      TempoSorting: true,
      search: ''
    }
  },
  components: {
    Loading,
    UserBar
  },
  beforeMount () {

  },
  mounted () {
    this.getProcessCancelList()
  },
  methods: {
    random () {
      return _.random(1000)
    },
    SortByDay () {
      if (this.TempoSorting === true) {
        this.CancelsData.sort((a, b) => a.dia < b.dia ? 1 : -1)
        this.TempoSorting = false
      } else {
        this.CancelsData.sort((a, b) => a.dia > b.dia ? 1 : -1)
        this.TempoSorting = true
      }
    },
    customFilter () {
      let SString = this.search.toLowerCase()
      let CArray = this.CancelsData

      let results = CArray.filter(function (opn) {
        return opn.nome.toLowerCase().includes(SString) || opn.detalhes_cancelamento.toLowerCase().includes(SString) || opn.processo.toLowerCase().includes(SString)
      })

      this.CancelsDataFiltered = results
    },
    getProcessCancelList () {
      this.isLoading = true

      KpiService.getProcessCancelList(this.period).then(res => {
        this.CancelsData = res.data.data
        this.CancelsDataFiltered = this.CancelsData
        this.isLoading = false
      })
    },
    updatePeriod (periodUpdated) {
      this.period = periodUpdated
    },
    format_date (value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
      }
    }
  },
  computed: {

  },
  watch: {
    period: {
      handler () {
        this.getProcessCancelList()
      },
      deep: true
    },
    search: {
      handler () {
        this.customFilter()
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
  }
</style>
